import { createRouter, createWebHistory } from 'vue-router';
import axios from 'axios';
import store from '../store.js'; // Importa o store

const routes = [
  {
    path: '/admin/chat/:conversaId?',
    name: 'chat',
    component: () => import(/* webpackChunkName: "chat" */ '../views/ChatView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Chat', title: 'Minhas Conversas Inteligentes' }
  },
  {
    path: '/admin/login/:logout?',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
    meta: { breadcrumb: 'Login', title: 'Login' }
  },
  {
    path: '/admin/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/RegisterView.vue'),
    meta: { breadcrumb: 'Register', title: 'Register' }
  },
  {
    path: '/admin/configurar',
    name: 'configurar',
    component: () => import(/* webpackChunkName: "configurar" */ '../views/ConfigView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Configurar', title: 'Configurar' }
  },
  {
    path: '/admin/enviar-mensagem',
    name: 'enviar-mensagem',
    component: () => import(/* webpackChunkName: "enviar-mensagem" */ '../views/EnviarMensagemView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Enviar Mensagem', title: 'Enviar Mensagem' }
  },
  {
    path: '/admin/primeiros-passos',
    name: 'primeiros-passos',
    component: () => import(/* webpackChunkName: "primeiros-passos" */ '../views/PrimeirosPassosView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Primeiros Passos', title: 'Primeiros Passos' }
  },
  {
    path: '/admin/prompts',
    name: 'prompts',
    component: () => import(/* webpackChunkName: "prompts" */ '../views/ListarPromptsView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Prompts', title: 'Prompts' }
  },
  {
    path: '/admin/prompt/novo/:idIntegracao/:nomeServico',
    name: 'novo-prompt',
    component: () => import(/* webpackChunkName: "novo-prompt" */ '../views/PromptView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Novo Prompt', title: 'Novo Prompt' }
  },
  {
    path: '/admin/prompt/editar/:idPrompt',
    name: 'editar-prompt',
    component: () => import(/* webpackChunkName: "editar-prompt" */ '../views/PromptView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Editar Prompt', title: 'Editar Prompt' }
  },
  {
    path: '/admin/assinatura',
    name: 'assinatura',
    component: () => import(/* webpackChunkName: "assinatura" */ '../views/AssinaturaView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'assinatura', title: 'Assinatura' }
  },
  {
    path: '/admin/recuperar-senha',
    name: 'recuperar-senha',
    component: () => import(/* webpackChunkName: "recuperar-senha" */ '../views/PasswordRecoveryView.vue'),
    meta: { breadcrumb: 'Recuperar Senha', title: 'Recuperar Senha' }
  },
  {
    path: '/admin/meus-dados',
    name: 'meus-dados',
    component: () => import(/* webpackChunkName: "assinatura" */ '../views/MeusDadosView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Meus Dados', title: 'Meus Dados' }
  },
  {
    path: '/admin/listar-tags',
    name: 'listar-tags',
    component: () => import(/* webpackChunkName: "listar-tags" */ '../views/ListarTags.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Listar Tags', title: 'Listar Tags' }
  },
  {
    path: '/admin/disparo-em-massa',
    name: 'disparo-em-massa',
    component: () => import(/* webpackChunkName: "disparo-em-massa" */ '../views/DisparoMensagensEmMassaView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Envio de mensagens em massa', title: 'Envio de mensagens' }
  },
  {
    path: '/admin/disparo-em-massa/editar/:idEnvioMensagem',
    name: 'editar-disparo-em-massa',
    component: () => import(/* webpackChunkName: "disparo-em-massa" */ '../views/CriarEditarDisparoMensagensView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Editar envio de mensagens', title: 'Editar envio de mensagens' }
  },
  {
    path: '/admin/disparo-em-massa/novo',
    name: 'novo-disparo-em-massa',
    component: () => import(/* webpackChunkName: "disparo-em-massa" */ '../views/CriarEditarDisparoMensagensView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Novo envio de mensagens', title: 'Novo envio de mensagens' }
  },
  {
    path: '/admin/historico-pagamento',
    name: 'historico-pagamento',
    component: () => import(/* webpackChunkName: "disparo-em-massa" */ '../views/HistoricoPagamentoView.vue'),
    meta: { requiresAuth: true, breadcrumb: 'Histórico de Pagamentos e Recargas', title: 'Histórico de Pagamentos e Recargas' }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Função genérica para atualizar o título do documento
function updateDocumentTitle(to) {
  document.title = to.meta.title || 'Chat Inteligente';
}

// Adiciona um global before guard para verificar autenticação e atualizar o título do documento
router.beforeEach(async (to, from, next) => {
  updateDocumentTitle(to); // Atualiza o título antes da navegação

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const token = localStorage.getItem('token_chat_inteligente');

  if (requiresAuth && !token) {
    return next('/admin/login');
  }

  if (requiresAuth) {
    try {
      const response = await axios.post('/api/getUserInfo', { token_chat_inteligente: token });
      if (response.data.success) {
        const user = response.data.dados_usuario;
        store.commit('setUser', user);  // Salva o usuário no store
        store.commit('setToken', token);  // Salva o token no store
        next();
      } else {
        next('/admin/login');
      }
    } catch (error) {
      console.log("ERRO", error);
      next('/admin/login');
    }
  } else {
    next();
  }
});

// Adiciona um afterEach guard para atualizar os breadcrumbs no store e o título do documento
router.afterEach((to) => {
  store.dispatch('breadcrumb/updateBreadcrumbs', to); // Chama a ação em vez da mutação
  updateDocumentTitle(to); // Atualiza o título após a navegação
});

export default router;
