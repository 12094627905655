import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import store from './store.js';  // Importa o store
import phoneMask from './directives/phoneMask';
import cpfCnpjMask from './directives/cpfCnpjMask';
import { createMetaManager } from 'vue-meta';
import { registerGlobalComponents } from './globalComponents.js';

const app = createApp(App);

// Registrar componentes globais
registerGlobalComponents(app);

// Crie uma instância do meta manager
const metaManager = createMetaManager();

app.use(store);
app.use(router);
app.use(metaManager);

app.directive('phone-mask', phoneMask);
app.directive('cpf-cnpj-mask', cpfCnpjMask);
app.mount('#app');
