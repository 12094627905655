import { createStore } from 'vuex';
import axios from 'axios';

import alert from './store/modules/alert.js'; // Importe o módulo de alertas

import breadcrumb from './store/modules/breadcrumb.js'; // Importe o módulo de breadcrumb
 import alertaDeConfirmacao from './store/modules/alertaDeConfirmacao.js'; // Importe o módulo de alerta de confirmação
export default createStore({
  state: {
    user: {},
    token: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
  },
  actions: {
    async fetchUser({ commit }) {
      const token = localStorage.getItem('token_chat_inteligente');
      try {
        const response = await axios.post('/api/getUserInfo', { token_chat_inteligente: token });
        if (response.data.success) {
          const user = response.data.dados_usuario;
          commit('setUser', user);
        } else {
          console.error('Erro ao buscar usuário:', response.data.message);
        }
      } catch (error) {
        console.error('Erro ao buscar usuário:', error);
      }
    },
  },
  getters: {
    user: state => state.user,
    token: state => state.token,
  },
  modules: {
    alert, // Inclua o módulo de alertas
    breadcrumb, // Inclua o módulo de breadcrumb
    alertaDeConfirmacao // Inclua o módulo de alerta de confirmação
  }
});
