import { nextTick } from 'vue';

const state = {
  showAlert: false,
  alertMessage: '',
  alertTitle: '',
  confirmCallback: null
};

const getters = {
  showAlert: state => state.showAlert,
  alertMessage: state => state.alertMessage,
  alertTitle: state => state.alertTitle
};

const mutations = {
  SET_ALERT(state, { title, message, callback }) {
    nextTick(() => {
      state.alertTitle = title;
      state.alertMessage = message;
      state.confirmCallback = callback;
      state.showAlert = true;
    });
  },
  HIDE_ALERT(state) {
    nextTick(() => {
      state.showAlert = false;
      state.alertMessage = '';
      state.alertTitle = '';
      state.confirmCallback = null;
    });
  }
};

const actions = {
  triggerAlert({ commit }, payload) {
    console.log("ALERT TRIGADO");
    commit('SET_ALERT', payload);
  },
  confirmAlert({ state, commit }) {
    if (state.confirmCallback) {
      state.confirmCallback();
    }
    commit('HIDE_ALERT');
  },
  cancelAlert({ commit }) {
    commit('HIDE_ALERT');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
